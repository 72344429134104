<template>
  <!-- emoji-input 組件 -->
  <div
    contenteditable
    class="single-line"
    :placeholder="placeholder"
    @input="onInput"
    @keydown="$emit('keydown', $event)"
    @keyup="$emit('keyup', $event)"
    @keypress="$emit('keypress', $event)"
    @focus="$emit('focus')"
    @focusin="$emit('focusin')"
    @blur="$emit('focusout')"
    @paste="handlePaste"
  ></div>
</template>

<script>
  export default {
    name: 'emoji-input',
    props: {
      placeholder: {
        type: String,
        default: () => '請輸入文字...',
      },
      maxlength: {
        type: Number,
        default: -1,
      },
    },
    data() {
      return {
        rawValue: '',
        lastInnerHTML: '',
        lastSelChildIndex: -1,
        lastSelOffset: -1,
      };
    },
    mounted() {
      window.emojiInput = this;
    },
    watch: {},
    computed: {
      emojiDataRaw() {
        return this.$store.state.Chat.emojiDataRaw;
      },
      value: {
        get: function () {
          // return (this.rawValue || '').trim();
          return this.rawValue || '';
        },
        set: function (newVal) {
          this.rawValue = newVal || '';
        },
      },
    },
    methods: {
      async onInput() {
        const len = Array.from(this.$el.childNodes)
          .map((n) => (n.nodeType === 3 ? n.textContent.length : n.nodeType === 1 ? 1 : 0))
          .reduce((acc, itm) => acc + itm, 0);

        if (this.maxlength !== -1) {
          if (len > this.maxlength) {
            const sel = window.getSelection();
            const children = Array.from(this.$el.childNodes);
            this.lastSelChildIndex = children.indexOf(sel.anchorNode);
            this.lastSelOffset = sel.anchorOffset;

            this.$el.innerHTML = this.lastInnerHTML;
            this.$el.focus();

            const newChildren = Array.from(this.$el.childNodes);
            let lastSelNode = newChildren[this.lastSelChildIndex];

            if (!lastSelNode) {
              lastSelNode = newChildren[newChildren.length - 1];
              this.lastSelOffset = lastSelNode.textContent.length;
            }

            const range = document.createRange();
            range.setStart(lastSelNode, Math.max(0, this.lastSelOffset - 1));
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);

            this.lastSelChildIndex = -1;
            this.lastSelOffset = -1;

            // console.warn('已達字數上限: ', this.maxlength);
          } else {
            this.lastInnerHTML = this.$el.innerHTML;
          }
        }
        this.value = this.toText(this.lastInnerHTML);
        this.$emit('input', this.value);
      },
      handlePaste(e) {
        // 只能貼文字
        e.preventDefault();
        document.execCommand('insertText', false, e.clipboardData.getData('text'));
      },
      toText(htmlText) {
        // 把 HTML Text 解析成帶 uniqueKey 的純文字訊息
        const html = htmlText || this.$el.innerHTML;
        const tmpEl = document.createElement('div');
        tmpEl.innerHTML = html;
        tmpEl.querySelectorAll('img[attr=emoji]').forEach((imgEl) => {
          const uniqueKey = imgEl.getAttribute('uniqueKey');
          imgEl.insertAdjacentHTML('afterend', `${uniqueKey}`);
          imgEl.remove();
        });
        tmpEl.innerHTML = tmpEl.innerHTML.replace(/(<([^>]+)>)/gi, '');
        const result = tmpEl.textContent;
        tmpEl.remove();
        return result;
      },
      insertEmojiToInput(base64, gid, uniqueKey) {
        this.$el.focus();
        if (window.getSelection) {
          const sel = window.getSelection();
          if (document.activeElement !== this.$el) this.$el.focus();
          if (sel.getRangeAt && sel.rangeCount) {
            const img = document.createElement('img');
            img.src = base64;
            img.setAttribute('attr', 'emoji');
            img.setAttribute('gid', gid);
            img.setAttribute('uniqueKey', uniqueKey);

            const range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(img);
            range.collapse(null);
            // 光標定位到中間
            this.$el.scrollLeft = img.offsetLeft - this.$el.clientWidth / 2;
            this.onInput();
            this.$el.focus();
          }
        }
      },
      insertTextAtCaret(text) {
        var sel, range;
        if (window.getSelection) {
          sel = window.getSelection();
          if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            const textNode = document.createTextNode(text);
            range.insertNode(textNode);
            sel.removeAllRanges();
          }
        } else if (document.selection && document.selection.createRange) {
          document.selection.createRange().text = text;
        }
        this.onInput();
        this.$el.focus();
      },
      focus() {
        this.$el.focus();
        this.placeCaretAtEnd();
      },
      placeCaretAtEnd() {
        const el = this.$el;
        el.focus();
        if (
          typeof window.getSelection !== 'undefined' &&
          typeof document.createRange !== 'undefined'
        ) {
          var range = document.createRange();
          range.selectNodeContents(el);
          range.collapse(false);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (typeof document.body.createTextRange !== 'undefined') {
          var textRange = document.body.createTextRange();
          textRange.moveToElementText(el);
          textRange.collapse(false);
          textRange.select();
        }
      },
      setInputValue(val) {
        // 從外部設定input值
        this.$el.innerText = val || '';
        this.onInput();
      },
      setInputHTML(val) {
        // 從外部設定input HTML值
        this.$el.innerHTML = val || '';
        this.onInput();
      },
    },
  };
</script>

<style lang="scss" scoped>
  [contenteditable] {
    user-select: text !important; // ios
    cursor: text;
    color: #000;
    width: 100%;
    height: 100%;
    &:empty:before {
      content: attr(placeholder);
      color: rgba(50, 50, 50, 0.5);
    }
  }

  /* single-line */
  [contenteditable].single-line {
    white-space: pre; // nowrap 無法保留文末空格
    overflow-y: hidden;
    overflow-x: overlay;
    &::-webkit-scrollbar {
      display: none;
    }

    &::v-deep br {
      display: none;
    }
    &::v-deep * {
      display: inline;
      white-space: nowrap;
    }
    &::v-deep img[attr='emoji'] {
      height: 80%;
      max-height: 25px;
      width: auto;
      margin: 0 2px;
      vertical-align: middle;
    }
  }

  /* focus */
  [contenteditable]:focus-visible {
    outline: 0;
  }
</style>
