<template>
  <table class="table-summary" :class="isOneRowMode ? 'isOneRowMode' : ''" :CatID="CatID">
    <template v-if="CatID === 98">
      <template v-if="[72, 83, 84].includes(leage.CatID)">
        <tr>
          <td>
            <div class="time"></div>
          </td>
          <td>
            <div class="team"></div>
          </td>
          <td v-for="(title, index) in titles" :key="index">
            <div v-if="title.Key !== 'Remarks'" class="score">
              <div class="cell">{{ teamData[title.Key] }}</div>
            </div>
            <div v-else class="cell">{{ teamData.Remarks }}</div>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td>
            <div class="time">
              <div class="cell">{{ parseDate(teamData.ScheduleTimeStr)[0] }}</div>
              <div class="cell">{{ parseDate(teamData.ScheduleTimeStr)[1] }}</div>
            </div>
          </td>
          <td>
            <div class="team">
              <div class="cell">{{ teamData.HomeTeam }}</div>
              <div class="cell">{{ teamData.AwayTeam }}</div>
            </div>
          </td>
          <td v-for="(title, index) in titles" :key="index">
            <div
              v-if="title.Key !== 'Remarks'"
              class="score"
              :class="getWinnerClassByKey(title.Key)"
            >
              <div class="cell">{{ parseScore(title.Key)[0] }}</div>
              <div class="cell">{{ parseScore(title.Key)[1] }}</div>
            </div>
            <div v-else class="cell">{{ teamData.Remarks }}</div>
          </td>
        </tr>
      </template>
    </template>
    <template v-else-if="isOneRowMode">
      <tr>
        <td>
          <div class="time"> </div>
        </td>
        <!-- 項目 -->
        <td v-for="(title, index) in titles" :key="index">
          <div class="score" v-if="title.Key !== 'Remarks'">
            <div class="cell">{{ teamData[title.Key] }}</div>
          </div>

          <div class="cell" v-else>{{ teamData.Remarks }}</div>
        </td>
      </tr>
    </template>
    <template v-else-if="[72, 84].includes(CatID)">
      <tr>
        <td>
          <div class="time">
            <div class="cell">{{ parseDate(teamData.ScheduleTimeStr)[0] }}</div>
            <div class="cell">{{ parseDate(teamData.ScheduleTimeStr)[1] }}</div>
          </div>
        </td>
        <td>
          <div class="team">
            <div class="cell">{{ teamData.HomeTeam }}</div>
          </div>
        </td>
        <td v-for="(title, index) in titles" :key="index">
          <div v-if="title.Key !== 'Remarks'" class="score">
            <div class="cell">{{ teamData[title.Key] }}</div>
          </div>
          <div v-else class="cell">{{ teamData.Remarks }}</div>
        </td>
      </tr>
    </template>
    <!-- 正常有主客隊的排版 -->
    <template v-else>
      <tr>
        <!-- 時間 -->
        <td>
          <div class="time">
            <div class="cell">{{ parseDate(teamData.ScheduleTimeStr)[0] }}</div>
            <div class="cell">{{ parseDate(teamData.ScheduleTimeStr)[1] }}</div>
          </div>
        </td>
        <!-- 隊伍 -->
        <td>
          <div class="team">
            <div class="cell">
              <!-- team image -->
              <template v-if="teamImages.includes(CatID)">
                <img
                  v-if="teamData.HomeImage"
                  class="team-image"
                  :src="teamImagePath + teamData.HomeImage"
                  onerror="javascript:this.src='/img/no.png'"
                />
                <img v-else class="team-image" src="@/assets/img/no.png" />
              </template>

              {{ teamData.HomeTeam }}
            </div>
            <div class="cell">
              <!-- team image -->
              <template v-if="teamImages.includes(CatID)">
                <img
                  v-if="teamData.AwayImage"
                  class="team-image"
                  :src="teamImagePath + teamData.AwayImage"
                  onerror="javascript:this.src='/img/no.png'"
                />
                <img v-else class="team-image" src="@/assets/img/no.png" />
              </template>
              {{ teamData.AwayTeam }}</div
            >
          </div>
        </td>
        <!-- 分數 -->
        <td v-for="(title, index) in titles" :key="index">
          <div class="score" :class="getWinnerClassByKey(title.Key)" v-if="title.Key !== 'Remarks'">
            <div class="cell">{{ parseScore(title.Key)[0] }}</div>
            <div class="cell">{{ parseScore(title.Key)[1] }}</div>
          </div>

          <div class="cell" v-else>{{ teamData.Remarks }}</div>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
  export default {
    name: 'GameResultTable',
    props: {
      leage: { type: Object },
      titles: {
        type: Array,
        default() {},
      },
      teamData: {
        type: Object,
        default() {
          return {};
        },
      },
      isOneRowMode: {
        type: Boolean,
        default() {
          return false;
        },
      },
      CatID: {
        type: Number,
        default() {
          return -1;
        },
      },
    },
    computed: {
      teamImagePath() {
        // return 'https://queen168.net/timage/';
        return '/timage/';
      },
      teamImages() {
        return [1, 31, 101, 102];
      },
    },
    methods: {
      parseScore(key) {
        const str = this.teamData?.[key];
        if (str && str.indexOf(':') !== -1) {
          return str.split(':');
        }
        return ['', ''];
      },
      parseDate(str) {
        const d = new Date(str);
        if (d instanceof Date && !isNaN(d)) {
          const date =
            (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
            '-' +
            (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());

          const time =
            (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) +
            ':' +
            (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes());
          return [date, time];
        }
        return ['', ''];
      },
      getWinnerClassByKey(key) {
        let [hs, as] = this.parseScore(key);
        hs = parseInt(hs);
        as = parseInt(as);
        return hs > as ? 'homeWin' : hs < as ? 'awayWin' : '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .team-image {
    position: relative;
    top: 3px;
    width: 16px;
  }

  table.table-summary {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background-color: #fff;

    &:hover {
      background-color: #f7f7f7;
    }

    td:nth-child(1) {
      width: 55px;
    }
    td:nth-child(2) {
      width: 255px;
    }

    .cell-detail {
      cursor: pointer;
      i {
        transform: scaleY(1);
      }
      &.active {
        background-color: #cce6ff;
        i {
          transform: scaleY(-1);
        }
      }
    }

    td {
      height: 55px;
      text-align: center;
      border-left: 1px solid #f3f3f3;
      border-bottom: 1px solid #eee;
      line-height: 1rem;
      overflow: hidden;
      word-break: break-word;
      .time {
        color: #888;
      }
      .team {
        text-align: left;
        padding-left: 1rem;
      }
      .score {
        &.homeWin {
          .cell:nth-child(1) {
            color: #ff8800;
          }
        }
        &.awayWin {
          .cell:nth-child(2) {
            color: #ff8800;
          }
        }
      }
      .cell {
        padding: 3px 0;

        .light {
          color: #ff8800;
        }
      }
    }

    &.isOneRowMode {
      td:nth-child(1) {
      }
      td:nth-child(2) {
        border-left: 0;
      }
      td:nth-child(3) {
      }
    }
  }

  table.table-details {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background-color: #f8f8f8;

    td.item {
      height: 30px;
      padding: 0 1rem;
      border-right: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      .name {
        float: left;
        color: #000;
      }
      .value {
        float: left;
        color: #ff8800;
        margin-left: 1rem;
      }
    }
  }

  /* 個別球種要設定不同td寬度 */
  table.table-summary {
    // 足球
    &[CatID='1'] {
      td:nth-child(2) {
        width: auto;
      }
      td:nth-child(n + 3) {
        width: 75px;
      }
    }

    // 世界盃
    &[CatID='31'] {
      td:nth-child(2) {
        width: auto;
      }
      td:nth-child(n + 3) {
        width: 75px;
      }
    }

    // 冰球
    &[CatID='82'] {
      td:nth-child(2) {
        width: auto;
      }
      td:nth-child(n + 3) {
        width: 75px;
      }
    }

    // 撞球
    &[CatID='24'] {
      td:nth-child(2) {
        width: auto;
      }
      td:nth-child(n + 3) {
        width: 75px;
      }
    }
    // 賽馬賽狗
    &[CatID='72'] {
      td:nth-child(2) {
        width: auto;
      }
      td:nth-child(n + 3) {
        width: 75px;
      }
    }
    // 指數
    &[CatID='84'] {
      td:nth-child(2) {
        width: auto;
      }
      td:nth-child(n + 3) {
        width: 75px;
      }
    }
  }
</style>
