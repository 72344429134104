<template>
  <div id="m-drawer" :class="{ active: isOpen }">
    <div class="drawer-bg" @click.stop="closeDrawer()" />
    <div class="drawer-panel">
      <div class="panel-header">
        <img class="user-level" src="@/assets/img/mobile/icon_level1.svg" />
        <div class="user-account">{{ userAccount }}</div>
        <div class="user-name">{{ userName }}</div>
        <div class="panel-announcements" @click="showAnnouncements()">
          <img class="announcements-icon" src="@/assets/img/mobile/btn_announcement.svg" />
          <div v-show="hasUnreadAnnouncements" class="announcements-unread" />
        </div>
      </div>
      <div class="panel-body">
        <div class="panel-items">
          <div class="panel-item" @click="showLiveScore()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_score.svg" />
            <div class="item-name">{{ $t('GamesHeader.LiveScore') }}</div>
          </div>
          <div class="panel-item" @click="showGameResult()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_result.svg" />
            <div class="item-name">{{ $t('GamesHeader.GameResult') }}</div>
          </div>
          <div class="panel-item" @click="showRules()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_rule.svg" />
            <div class="item-name">{{ $t('GamesHeader.GameRule') }}</div>
          </div>

          <div class="panel-item" @click="showPersonalPanel(1)">
            <img class="panel-icon" src="@/assets/img/mobile/icon_header_user.svg" />
            <div class="item-name">{{ $t('Common.Profile') }}</div>
          </div>
          <div v-if="!siteLabel" class="panel-item" @click="showPersonalPanel(2)">
            <img class="panel-icon" src="@/assets/img/mobile/icon_header_user.svg" />
            <div class="item-name">{{ $t('Common.ChangePassword') }}</div>
          </div>
          <div class="panel-item" @click="openLimits()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_header_user.svg" />
            <div class="item-name">{{ $t('Common.Limit') }}</div>
          </div>

          <div
            class="panel-item"
            @click="
              toggleTheme();
              $store.dispatch('Setting/save');
            "
          >
            <img class="panel-icon" src="@/assets/img/mobile/icon_exterior.svg" />
            <div class="item-name">{{ $t('Common.ThemeMode') }}</div>
          </div>
          <div class="panel-item" @click="showStrayCounter()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_count.svg" />
            <div class="item-name">{{ $t('GamesBetInfo.StrayCount') }}</div>
          </div>
          <div class="panel-item" @click="switchToPcVersion()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_screen.svg" />
            <div class="item-name">{{ $t('Login.SwitchToPCVersion') }}</div>
          </div>
          <div v-if="userDGLiveOpen" class="panel-item" @click="openDGLive()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_screen.svg" />
            <div class="item-name">{{ $t('Common.DGLive') }}</div>
            <div v-if="DGLiveBalance || DGLiveBalance === 0" class="item-info">{{
              `$${toCurrency(DGLiveBalance)}`
            }}</div>
          </div>
          <div v-if="userSPLotteryOpen" class="panel-item" @click="openSupperLottery()">
            <img class="panel-icon" src="@/assets/img/mobile/icon_screen.svg" />
            <div class="item-name">{{ $t('Common.SLottery') }}</div>
            <div v-if="SPLotteryBalance || SPLotteryBalance === 0" class="item-info">{{
              `$${toCurrency(SPLotteryBalance)}`
            }}</div>
          </div>
        </div>
        <mAdvancedSettings class="panel-settings" @updateGameDetail="$emit('updateGameDetail')" />
      </div>
      <div v-if="!siteLabel" class="panel-footer">
        <div class="panel-logout" @click="logout">
          <img class="logout-icon" src="@/assets/img/mobile/logout.svg" />
          <div class="logout-name">{{ $t('GamesHeader.Logout') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import mAdvancedSettings from './mAdvancedSettings.vue';
  import {
    apiGetDGLiveLogin,
    apiGetSPLotteryLogin,
    apiGetDGLiveBalance,
    apiGetSPLotteryBalance,
  } from '@/api/third';
  import { PageEnum } from '@/config/mobile/enum';
  import { gotoPcHost } from '@/config/host';
  import { toCurrency } from '@/config/balance';

  export default {
    components: {
      mAdvancedSettings,
    },
    props: {
      isOpen: {
        type: Boolean,
      },
    },
    data() {
      return {
        PageEnum,
        DGLiveBalance: false,
        SPLotteryBalance: false,
        settingsShow: false,
      };
    },
    computed: {
      ...mapState(['siteLabel', 'userBalance', 'darkTheme']),
      ...mapGetters(['userAccount', 'userName', 'userDGLiveOpen', 'userSPLotteryOpen']),
      hasUnreadAnnouncements() {
        return this.$store.getters['Game/hasUnreadAnnouncements'];
      },
    },
    watch: {
      isOpen(open) {
        if (open) {
          if (this.userDGLiveOpen) {
            apiGetDGLiveBalance().then((response) => {
              this.DGLiveBalance = response?.data?.Balance;
            });
          }
          if (this.userSPLotteryOpen) {
            apiGetSPLotteryBalance().then((response) => {
              this.SPLotteryBalance = response?.data?.Balance;
            });
          }
        }
      },
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading', 'toggleTheme']),
      toCurrency,
      openLimits() {
        const limits = this.$router.resolve({
          path: 'Limits',
        });
        window.open(limits.href, 'limits');
      },
      openDGLive() {
        const newWindow = window.open();
        newWindow.opener = null;
        this.showLoading();
        apiGetDGLiveLogin()
          .then((res) => {
            const url = res.data?.GameUrl;
            if (url && newWindow) {
              newWindow.location.href = url;
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      },
      openSupperLottery() {
        if (this.userSPLotteryOpen) {
          this.showLoading();
          apiGetSPLotteryLogin()
            .then((response) => {
              const url = response.data?.GameUrl;
              if (url) {
                const a = btoa(url);
                window.open(`/SPLottery.html?${a}`, 'SPLottery');
              }
            })
            .finally(() => {
              this.hideLoading();
            });
        } else {
          this.$message({
            type: 'info',
            message: this.$t('GamesHeader.Lottery1'),
            duration: 4000,
          });
        }
      },
      showLiveScore() {
        this.$emit('goPage', PageEnum.liveScore);
        this.closeDrawer();
      },
      showAnnouncements() {
        this.$emit('goPage', PageEnum.announcement);
        this.closeDrawer();
      },
      showGameResult() {
        this.$emit('goPage', PageEnum.gameResult);
        this.closeDrawer();
      },
      showRules() {
        this.$emit('goPage', PageEnum.rules);
        this.closeDrawer();
      },
      showPersonalPanel(type) {
        this.$emit('showPersonalPanel', type);
        this.closeDrawer();
      },
      showStrayCounter() {
        this.$emit('goPage', PageEnum.strayCounter);
        this.closeDrawer();
      },
      closeDrawer() {
        this.$emit('closeMe');
      },
      switchToPcVersion() {
        gotoPcHost();
      },
      logout() {
        this.showLoading();
        this.$store.dispatch('logout').finally(() => {
          this.hideLoading();
        });
      },
    },
  };
</script>

<style lang="scss">
  @keyframes announcements-unread {
    100% {
      transform: scale(0);
    }
  }

  #m-drawer {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    user-select: none;
    pointer-events: none;

    > .drawer-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: transparent;
      transition: background-color 400ms;
    }

    > .drawer-panel {
      display: flex;
      flex-flow: column nowrap;
      position: absolute;
      width: 80%;
      max-width: 320px;
      min-width: 200px;
      height: 100%;
      right: 0;
      top: 0;
      transform: translateX(100%);
      background-color: #eee;
      transition: transform 800ms ease;

      > .panel-header {
        flex: 0 0 48px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid #ccc;
        color: #000;
        font-size: 16px;
        line-height: 16px;

        > .user-level {
          width: 24px;
        }

        > .user-account {
          margin-left: 8px;
        }

        > .user-name {
          margin-left: 8px;
          color: #0169ff;
        }

        > .panel-announcements {
          position: relative;
          width: 36px;
          padding: 4px;
          margin-left: auto;
          transition: transform 400ms ease;
          cursor: pointer;

          > .announcements-icon {
            width: 100%;
          }

          > .announcements-unread {
            position: absolute;
            width: 12px;
            height: 12px;
            right: 4px;
            top: 0;
            border-radius: 50%;
            background-color: red;
            animation: announcements-unread 1200ms ease-in-out 1200ms infinite alternate;
          }

          &:hover,
          &:active {
            transform: scale(1.2);
          }
        }
      }

      > .panel-body {
        display: flex;
        flex-flow: column nowrap;
        height: calc(100% - 96px);
        overflow-y: auto;

        > .panel-items {
          flex: 0 0 fit-content;
          display: flex;
          flex-flow: row wrap;
          padding: 4px;

          > .panel-item {
            flex: 0 1 33%;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            padding: 8px;
            cursor: pointer;

            > .panel-icon {
              width: 24px;
              height: 24px;
              margin-top: 4px;
              margin-bottom: 8px;
              transition: transform 800ms ease;
            }

            > .item-name {
              color: #444;
              font-size: 14px;
              line-height: 14px;
            }

            > .item-info {
              margin-top: 4px;
              padding: 4px 8px;
              border-radius: 8px;
              background-color: #444;
              color: #fff;
              font-size: 13px;
              line-height: 13px;
            }

            &:hover,
            &:active {
              > .panel-icon {
                transform: scale(1.2);
              }
            }
          }
        }

        > .panel-settings {
          margin-top: 4px;
          border-top: 1px solid #ccc;
        }
      }

      > .panel-footer {
        flex: 0 0 48px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        border-top: 1px solid #ccc;
        color: #000;
        font-size: 14px;
        line-height: 14px;

        > .panel-logout {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding: 4px 8px;
          cursor: pointer;

          > .logout-icon {
            width: 24px;
            transition: transform 800ms ease;
          }

          > .logout-name {
            margin-left: 8px;
          }

          &:hover,
          &:active {
            > .logout-icon {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    &.active {
      pointer-events: auto;

      > .drawer-bg {
        background-color: rgba(0, 0, 0, 0.6);
      }

      > .drawer-panel {
        transform: translateX(0);
      }
    }
  }
</style>
