<template>
  <div id="limits" class="page">
    <main class="container">
      <header class="title"> 限紅返水 </header>
      <section class="cat">
        <ul v-if="catList" class="cat-list">
          <li
            v-for="cat in catList"
            :key="cat.CatID"
            :class="{ 'cat-item': true, active: cat.CatID === catId }"
            @click="showCatLiimits(cat)"
          >
            {{ cat.Name }}
          </li>
        </ul>
      </section>
      <section v-if="cat">
        <div class="cat-min"> 單注下限 : {{ cat.BetMin }} </div>
      </section>
      <section class="limits">
        <template v-if="limitsInfo">
          <ul v-if="limitsInfo.length > 0" class="limit-list">
            <li v-for="(group, index) in limitsInfo" :key="index" class="limit-group">
              <table class="group-table">
                <caption class="group-name"> {{ group.GroupName }} </caption>
                <thead>
                  <tr>
                    <th colspan="2" class="limit-head" />
                    <th
                      v-for="(head, headIndex) in group.BestHead"
                      :key="headIndex"
                      class="limit-head"
                    >
                      {{ head.Name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in group.Rows" :key="rowIndex">
                    <td colspan="2" class="limit-name-value">
                      {{ row.Name }}
                    </td>
                    <td
                      v-for="(head, headIndex) in group.BestHead"
                      :key="headIndex"
                      class="limit-value"
                    >
                      {{ row[head.KeyName] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
          <div v-else>無資料</div>
        </template>
      </section>
    </main>
  </div>
</template>

<script>
  import { apiGetLimitMenu, apiGetLimit } from '@/api/game';
  import { MOBILE_APP } from '@/config';

  export default {
    data() {
      return {
        catList: [],
        catId: 1,
        cat: null,
        limitsInfo: null,
        MOBILE_APP,
      };
    },
    methods: {
      showCatLiimits(cat) {
        this.cat = cat;
        this.catId = cat.CatID;
        this.$store.commit('showLoading');
        apiGetLimit(this.catId)
          .then((response) => {
            this.limitsInfo = response.data;
          })
          .finally(() => {
            this.$store.commit('hideLoading');
          });
      },
    },
    beforeCreate() {
      apiGetLimitMenu().then((response) => {
        this.catList = response.data;
        this.cat = this.catList[0];
        this.catId = this.cat.CatID;
        apiGetLimit(this.catId).then((response) => {
          this.limitsInfo = response.data;
        });
      });
    },
  };
</script>

<style lang="scss" scoped>
  #limits {
  }

  .title {
    padding: 16px;
    background-color: rgba(56, 166, 136, 1);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .cat {
    margin: 16px;
  }

  .cat-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 4px;
  }

  .cat-item {
    padding: 4px 16px;
    font-size: 16px;
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;

    &.active {
      background-color: rgba(56, 166, 136, 1);
    }
  }

  .cat-min {
    padding: 16px 32px;
    font-size: 16px;
  }

  .limits {
    padding: 16px 32px;
  }

  .limit-group {
    width: 100%;
  }

  .group-name {
    padding: 4px 0 4px 64px;
    font-size: 16px;
    text-align: left;
  }

  .group-table {
    width: 100%;
    margin: 2px auto 18px;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;

    th,
    td {
      padding: 8px 4px 4px;
      font-size: 14px;
      border: 1px solid #ccc;
    }
  }

  .limit-head {
  }
</style>
