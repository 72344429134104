<template>
  <div class="mGameInfo" :class="isExpanded ? '' : 'closed'">
    <table>
      <thead @click="$emit('toggleCollapse')">
        <tr>
          <th>
            <span class="title">{{ source.LeagueNameStr }}</span>
            <img class="arrow" src="@/assets/img/mobile/btn_arrow_w.svg" />
          </th>
        </tr>
      </thead>
      <tbody v-show="isExpanded">
        <template v-for="(teamData, teamIndex) in source.Team">
          <template v-if="teamData.EvtStatus === 1">
            <template v-for="(teamDataRowNum, rowIndex) in teamData.Row">
              <tr
                v-if="isFixLineShow || isLineShow(rowIndex)"
                :key="`${teamIndex}-${rowIndex}`"
                :set="(hasTie = teamData.hasDrewOdds && rowIndex === 0)"
              >
                <template v-if="isFix">
                  <td class="round-block" :class="hasTie ? 'rows-3' : 'rows-2'">
                    <div class="team-block">
                      <template v-if="teamData.SetFlag">
                        <div class="team">
                          <div v-if="teamData.hasOwnProperty('HomeTeamStr')" class="teamName">
                            {{ teamData.HomeTeamStr }}
                          </div>
                          <div
                            v-if="selectGameType === 2"
                            :class="{ light: teamData.HomeScore > teamData.AwayScore }"
                          >
                            {{ teamData.HomeScore }}
                          </div>
                        </div>
                        <div class="team">
                          <div v-if="teamData.hasOwnProperty('AwayTeamStr')" class="teamName">
                            {{ teamData.AwayTeamStr }}
                          </div>
                          <div
                            v-if="selectGameType === 2"
                            :class="{ light: teamData.AwayScore > teamData.HomeScore }"
                          >
                            {{ teamData.AwayScore }}
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="team">
                          <div v-if="teamData.hasOwnProperty('AwayTeamStr')" class="teamName">
                            {{ teamData.AwayTeamStr }}
                          </div>
                          <div
                            v-if="selectGameType === 2"
                            :class="{ light: teamData.AwayScore > teamData.HomeScore }"
                          >
                            {{ teamData.AwayScore }}
                          </div>
                        </div>
                        <div class="team">
                          <div v-if="teamData.hasOwnProperty('HomeTeamStr')" class="teamName">
                            {{ teamData.HomeTeamStr }}
                          </div>
                          <div
                            v-if="selectGameType === 2"
                            :class="{ light: teamData.HomeScore > teamData.AwayScore }"
                          >
                            {{ teamData.HomeScore }}
                          </div>
                        </div>
                      </template>
                      <div class="info-row">
                        <div
                          class="event-star"
                          :class="starCSSJudge(teamData.EvtID)"
                          @click="toggleEventFavorited(teamData.EvtID)"
                        />
                        <div v-if="selectGameType === 2" class="event-time playing">{{
                          teamData.TimeAct
                        }}</div>
                        <div v-else class="event-time">{{
                          $lib.timeFormatMMDD(teamData.ScheduleTimeStr) +
                          ' ' +
                          $lib.timeFormatHHmm(teamData.ScheduleTimeStr)
                        }}</div>
                        <img
                          v-if="teamData.Live"
                          class="event-live"
                          src="@/assets/img/common/btn_GDV_live.svg"
                          @click="showEventMore(teamData)"
                        />
                        <div
                          v-if="teamData.MoreCount !== -1"
                          class="event-more"
                          @click="showEventMore(teamData)"
                          >{{ teamData.MoreCount }}</div
                        >
                      </div>
                    </div>
                  </td>
                </template>
                <!-- 冠軍 -->
                <template v-else-if="selectCatID === GAME_CHAMPION_CAT">
                  <td class="round-block champion">
                    <div class="team-block">
                      <div class="team">
                        <!-- team image -->
                        <template v-if="teamImages.includes(source?.CatID)">
                          <img
                            v-if="teamData.HomeImage"
                            class="team-image"
                            :src="teamImagePath + teamData.HomeImage"
                            onerror="javascript:this.src='/img/no.png'"
                          />
                          <img v-else class="team-image" src="@/assets/img/no.png" />
                        </template>
                        <div class="teamName">
                          {{ teamData.HomeTeamStr }}
                        </div>
                      </div>
                    </div>
                  </td>
                </template>
                <td v-else class="round-block" :class="hasTie ? 'rows-3' : 'rows-2'">
                  <template v-if="rowIndex === 0">
                    <div class="team-block">
                      <!-- 顯示雙打1 -->
                      <template
                        v-if="
                          teamData.hasOwnProperty('HomePtAndNation') &&
                          teamData.HomePtAndNation.length !== 0
                        "
                      >
                        <div
                          class="team"
                          :title="`${teamData.HomePtAndNation[0]}-${teamData.HomePtAndNation[1]} / ${teamData.HomePtAndNation[2]}-${teamData.HomePtAndNation[3]}`"
                        >
                          <div class="teamName">
                            {{ teamData.HomePtAndNation[0] }}
                            <span class="teamPt">-{{ teamData.HomePtAndNation[1] }}</span>
                            <span> /</span>
                            {{ teamData.HomePtAndNation[2] }}
                            <span class="teamPt">-{{ teamData.HomePtAndNation[3] }}</span>
                          </div>
                          <div
                            v-if="isLive"
                            :class="teamData.HomeScore > teamData.AwayScore ? 'light' : ''"
                          >
                            {{ teamData.HomeScore }}
                          </div>
                        </div>
                      </template>
                      <!-- 顯示雙打2 -->
                      <template
                        v-if="
                          teamData.hasOwnProperty('AwayPtAndNation') &&
                          teamData.AwayPtAndNation.length !== 0
                        "
                      >
                        <div
                          class="team"
                          :title="`${teamData.AwayPtAndNation[0]}-${teamData.AwayPtAndNation[1]} / ${teamData.AwayPtAndNation[2]}-${teamData.AwayPtAndNation[3]}`"
                        >
                          <div class="teamName">
                            {{ teamData.AwayPtAndNation[0] }}
                            <span class="teamPt">-{{ teamData.AwayPtAndNation[1] }}</span>
                            <span> /</span>
                            {{ teamData.AwayPtAndNation[2] }}
                            <span class="teamPt">-{{ teamData.AwayPtAndNation[3] }}</span>
                          </div>
                          <div
                            v-if="isLive"
                            :class="teamData.AwayScore > teamData.HomeScore ? 'light' : ''"
                          >
                            {{ teamData.AwayScore }}
                          </div>
                        </div>
                      </template>
                      <!-- 只需要顯示一個隊伍 -->
                      <template
                        v-else-if="
                          teamData.hasOwnProperty('HomePtNameStr') &&
                          teamData.hasOwnProperty('HomeTeamStr') &&
                          teamData.AwayTeamStr === '.'
                        "
                      >
                        <div class="team">
                          <div class="teamName" v-if="teamData.hasOwnProperty('HomeTeamStr')"
                            >{{ teamData.HomeTeamStr }}

                            <span
                              class="teamPt"
                              v-if="teamData.HomePtNameStr !== '' && isShowPTName"
                            >
                              -{{ teamData.HomePtNameStr }}</span
                            >
                          </div>
                        </div>
                      </template>
                      <template
                        v-else-if="
                          teamData.hasOwnProperty('HomeTeamStr') &&
                          teamData.hasOwnProperty('AwayTeamStr') &&
                          teamData.HomeTeamStr === '.'
                        "
                      >
                        <div class="team"
                          >{{ teamData.AwayTeamStr }}
                          <span
                            class="teamPt"
                            v-if="
                              teamData.hasOwnProperty('AwayPtNameStr') &&
                              teamData.AwayPtNameStr !== '' &&
                              isShowPTName
                            "
                          >
                            -{{ teamData.AwayPtNameStr }}</span
                          >
                        </div>
                      </template>
                      <template v-else>
                        <template v-if="isSingleScore">
                          <div class="team" v-if="rowIndex % 2 === 0">
                            <div class="teamName" v-if="teamData.hasOwnProperty('HomeTeamStr')">
                              {{ teamData.HomeTeamStr }}
                              <span
                                class="teamPt"
                                v-if="
                                  teamData.hasOwnProperty('HomePtNameStr') &&
                                  teamData.HomePtNameStr !== '' &&
                                  isShowPTName
                                "
                              >
                                -{{ teamData.HomePtNameStr }}
                              </span>
                            </div>
                          </div>
                          <div class="team" v-else>
                            <div class="teamName" v-if="teamData.hasOwnProperty('AwayTeamStr')">
                              {{ teamData.AwayTeamStr }}
                              <span
                                class="teamPt"
                                v-if="
                                  teamData.hasOwnProperty('AwayPtNameStr') &&
                                  teamData.AwayPtNameStr !== '' &&
                                  isShowPTName
                                "
                              >
                                -{{ teamData.AwayPtNameStr }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <!-- 主客對調 -->
                          <template v-if="!teamData.SetFlag">
                            <div class="team">
                              <!-- team image -->
                              <template v-if="teamImages.includes(source?.CatID)">
                                <img
                                  v-if="teamData.AwayImage"
                                  class="team-image"
                                  :src="teamImagePath + teamData.AwayImage"
                                  onerror="javascript:this.src='/img/no.png'"
                                />
                                <img v-else class="team-image" src="@/assets/img/no.png" />
                              </template>
                              <div class="teamName" v-if="teamData.hasOwnProperty('AwayTeamStr')">
                                <span
                                  class="team-name"
                                  :class="{ emphasized: isEmphasizedTeam(teamData, 2) }"
                                  >{{ teamData.AwayTeamStr }}</span
                                >
                                <span
                                  class="teamPt"
                                  v-if="
                                    teamData.hasOwnProperty('AwayPtNameStr') &&
                                    teamData.AwayPtNameStr !== '' &&
                                    isShowPTName
                                  "
                                >
                                  -{{ teamData.AwayPtNameStr }}
                                </span>
                              </div>
                              <div
                                v-if="isLive"
                                :class="teamData.AwayScore > teamData.HomeScore ? 'light' : ''"
                              >
                                {{ teamData.AwayScore }}
                              </div>
                            </div>
                            <div class="team">
                              <!-- team image -->
                              <template v-if="teamImages.includes(source?.CatID)">
                                <img
                                  v-if="teamData.HomeImage"
                                  class="team-image"
                                  :src="teamImagePath + teamData.HomeImage"
                                  onerror="javascript:this.src='/img/no.png'"
                                />
                                <img v-else class="team-image" src="@/assets/img/no.png" />
                              </template>
                              <div class="teamName" v-if="teamData.hasOwnProperty('HomeTeamStr')">
                                <span
                                  class="team-name"
                                  :class="{ emphasized: isEmphasizedTeam(teamData, 1) }"
                                  >{{ teamData.HomeTeamStr }}</span
                                >
                                <span
                                  class="teamPt"
                                  v-if="
                                    teamData.hasOwnProperty('HomePtNameStr') &&
                                    teamData.HomePtNameStr !== '' &&
                                    isShowPTName
                                  "
                                >
                                  -{{ teamData.HomePtNameStr }}
                                </span>
                              </div>
                              <div
                                v-if="isLive"
                                :class="teamData.HomeScore > teamData.AwayScore ? 'light' : ''"
                              >
                                {{ teamData.HomeScore }}
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="team">
                              <!-- team image -->
                              <template v-if="teamImages.includes(source?.CatID)">
                                <img
                                  v-if="teamData.HomeImage"
                                  class="team-image"
                                  :src="teamImagePath + teamData.HomeImage"
                                  onerror="javascript:this.src='/img/no.png'"
                                />
                                <img v-else class="team-image" src="@/assets/img/no.png" />
                              </template>
                              <div class="teamName" v-if="teamData.hasOwnProperty('HomeTeamStr')">
                                <span
                                  class="team-name"
                                  :class="{ emphasized: isEmphasizedTeam(teamData, 1) }"
                                  >{{ teamData.HomeTeamStr }}</span
                                >
                                <span
                                  class="teamPt"
                                  v-if="
                                    teamData.hasOwnProperty('HomePtNameStr') &&
                                    teamData.HomePtNameStr !== '' &&
                                    isShowPTName
                                  "
                                >
                                  -{{ teamData.HomePtNameStr }}
                                </span>
                              </div>
                              <div
                                v-if="isLive"
                                :class="teamData.HomeScore > teamData.AwayScore ? 'light' : ''"
                              >
                                {{ teamData.HomeScore }}
                              </div>
                            </div>
                            <div class="team">
                              <!-- team image -->
                              <template v-if="teamImages.includes(source?.CatID)">
                                <img
                                  v-if="teamData.AwayImage"
                                  class="team-image"
                                  :src="teamImagePath + teamData.AwayImage"
                                  onerror="javascript:this.src='/img/no.png'"
                                />
                                <img v-else class="team-image" src="@/assets/img/no.png" />
                              </template>
                              <div class="teamName" v-if="teamData.hasOwnProperty('AwayTeamStr')">
                                <span
                                  class="team-name"
                                  :class="{ emphasized: isEmphasizedTeam(teamData, 2) }"
                                  >{{ teamData.AwayTeamStr }}</span
                                >
                                <span
                                  class="teamPt"
                                  v-if="
                                    teamData.hasOwnProperty('AwayPtNameStr') &&
                                    teamData.AwayPtNameStr !== '' &&
                                    isShowPTName
                                  "
                                >
                                  -{{ teamData.AwayPtNameStr }}
                                </span>
                              </div>
                              <div
                                v-if="isLive"
                                :class="teamData.AwayScore > teamData.HomeScore ? 'light' : ''"
                              >
                                {{ teamData.AwayScore }}
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>
                      <div class="team" v-if="rowIndex === 0 && teamData.hasDrewOdds">{{
                        $t('Common.DrewOdd')
                      }}</div>
                      <div class="info-row" v-if="rowIndex === 0 || isSingleScore">
                        <div
                          class="event-star"
                          :class="starCSSJudge(teamData.EvtID)"
                          @click="toggleEventFavorited(teamData.EvtID)"
                        />
                        <div class="event-time playing" v-if="selectGameType === 2"
                          >{{ teamData.TimeAct }}
                        </div>
                        <div v-else class="event-time"
                          >{{
                            $lib.timeFormatMMDD(teamData.ScheduleTimeStr) +
                            ' ' +
                            $lib.timeFormatHHmm(teamData.ScheduleTimeStr)
                          }}
                        </div>
                        <img
                          v-if="teamData.Live"
                          class="event-live"
                          src="@/assets/img/common/btn_GDV_live.svg"
                          @click="showEventMore(teamData)"
                        />
                        <div
                          v-if="teamData.MoreCount !== -1"
                          class="event-more"
                          @click="showEventMore(teamData)"
                          >{{ teamData.MoreCount }}</div
                        >
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { GAME_CHAMPION_CAT, GAME_HANDICAP_WAGERS } from '@/config';

  export default {
    data() {
      return {
        GAME_CHAMPION_CAT,
        teamImagePath: '/timage/',
      };
    },
    props: {
      BestHead: {
        type: Array,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      isExpanded: {
        type: Boolean,
        default() {
          return false;
        },
      },
      hasMoreGame: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    computed: {
      teamImages() {
        return this.$store.state.Game.teamImages;
      },
      gameStore() {
        return this.$store.state.Game;
      },
      selectGameType() {
        return this.$store.state.Game.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      isLive() {
        return this.selectGameType === 2;
      },
      // 是否 是單隊總得分
      isSingleScore() {
        if (this.BestHead[0].length !== 0) {
          const WagerTypeIDs = this.BestHead[0][0].WagerTypeIDs;
          const isFind = WagerTypeIDs.every((it) =>
            this.$conf.GAME_ONE_TEAM_SCORE_WAGERS.includes(it)
          );
          return isFind;
        } else {
          return false;
        }
      },
      isShowPTName() {
        const findData = this.$store.state.Game.CatList.find((it) => {
          return it.GroupCatIDs.indexOf(this.selectCatID) !== -1;
        });
        // 判斷是否為棒球
        let isBaseBall = false;
        if (findData?.CatID === 101) {
          isBaseBall = true;
        }
        // 滾球棒球不能顯示投手名
        if (this.selectGameType === 2 && isBaseBall) {
          return false;
        } else {
          return true;
        }
      },
      isFix() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 4:
              case 5:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isFixLineShow() {
        const { selectCatID, selectWagerTypeKey } = this;
        return (
          (selectCatID === 102 && selectWagerTypeKey === 6) ||
          (selectCatID === 101 && selectWagerTypeKey === 5)
        );
      },
    },
    methods: {
      isLineShow(rowIndex) {
        const linesShow = this.$store.state.Setting.UserSetting.tableLines;
        if (rowIndex === 0) return true;
        return linesShow;
      },
      isEmphasizedTeam(eventInfo, position) {
        if (eventInfo) {
          const { Wager: wagers } = eventInfo;
          if (wagers) {
            const targetWager = wagers.find(({ WagerTypeID }) =>
              GAME_HANDICAP_WAGERS.includes(WagerTypeID)
            );
            if (targetWager) {
              const { Odds: oddsList } = targetWager;
              if (oddsList && oddsList.length) {
                const targetInfo = oddsList[0];
                if (targetInfo && targetInfo.HdpPos) {
                  if (position === targetInfo.HdpPos) {
                    if (position === 1) {
                      return targetInfo.HomeHdp !== '0' && targetInfo.HomeHdp !== 'PK';
                    } else if (position === 2) {
                      return targetInfo.AwayHdp !== '0' && targetInfo.AwayHdp !== 'PK';
                    }
                  }
                  return position === targetInfo.HdpPos;
                }
              }
            }
          }
        }
        return false;
      },
      starCSSJudge(EvtID) {
        if (this.$store.state.Setting.UserSetting.favorites.indexOf(EvtID) > -1) {
          return 'active';
        } else {
          return '';
        }
      },
      toggleEventFavorited(EvtID) {
        this.$store.dispatch('Setting/addFavorites', EvtID);
      },
      showEventMore(teamData) {
        this.$store.dispatch('MoreGame/openMoreGameList', {
          teamData,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  $row-height: 2.4rem;
  $font-size: 1rem;

  .mGameInfo {
    position: relative;

    .team-image {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }

    .light {
      color: #ff8500 !important;
    }

    &.closed {
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background-color: #ccc;
      }
      th {
        overflow: unset;
        .title {
          position: absolute;
        }
      }
      th::after,
      th .arrow {
        transform: rotate(0deg);
      }
    }

    table {
      background-color: #ffffff;
      th {
        background-color: #e8e8e8;
      }
    }

    table {
      position: relative;
      table-layout: fixed;
      border-spacing: 0;
      width: 100%;
      font-size: $font-size;

      tr {
        position: relative;
      }

      th {
        display: flex;
        align-items: center;
        height: $row-height;
        position: relative;
        background-color: #e8e8e8;
        text-align: left;
        padding-left: 0.8rem;
        overflow: hidden;
        font-weight: normal;
        color: #444;
        cursor: pointer;

        .title {
          // width: calc(100% - 2.2rem);
          display: inline-block;
          overflow: auto;
          white-space: nowrap;
          // text-overflow: ellipsis;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          height: 70%;
          width: 1px;
          background-color: #888;
          display: none; // 因新UI需求先隱藏
        }

        img.arrow {
          width: 1.1rem;
          float: right;
          margin-right: 1rem;
          filter: invert(30%);
          transform: rotate(-90deg);
          transition: 200ms ease;
          display: none; // 因新UI需求先隱藏
        }
      }

      td.round-block {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        padding-left: 0.8rem;
        height: $row-height * 2;

        &.rows-2 {
          height: $row-height * 2;
        }

        &.rows-3 {
          height: $row-height * 3;
        }

        &.champion {
          height: $row-height;
        }

        .team-block {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          flex: auto;
          .team {
            color: #000;
            font-size: $font-size;
            display: flex;
            justify-content: space-between;
            margin-right: 2px;
            align-items: center;
            .teamName {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              flex-grow: 1;

              .team-name {
                &.emphasized {
                  color: #3fa381;
                }
              }
            }
          }
          .teamPt {
            color: gray;
          }

          .info-row {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            height: 16px;
            color: #888;

            .event-star {
              flex: 0 0 16px;
              width: 16px;
              height: 16px;
              margin-right: 6px;
              background: url('~@/assets/img/common/icon_star.svg') no-repeat center bottom;
              background-size: 100% auto;
              background-position: center bottom;
              background-repeat: no-repeat;
              cursor: pointer;

              &:hover,
              &:active,
              &.active {
                background-position: center top;
              }
            }

            .event-time {
              flex: 1 1 fit-content;
              margin-right: 6px;
              font-size: 13px;
              line-height: 13px;
              white-space: nowrap;

              &.playing {
                color: #ff8500;
              }
            }

            .event-live {
              flex: 0 0 16px;
              width: 16px;
              height: 16px;
              margin-right: 6px;
              object-fit: contain;
              filter: brightness(0.6);
              cursor: pointer;

              &:hover,
              &:active {
                filter: brightness(0.4);
              }
            }

            .event-more {
              flex: 0 0 fit-content;
              margin-right: 6px;
              color: #000;
              font-size: 15px;
              line-height: 15px;
              animation: event-more 1600ms ease-in-out 1600ms infinite alternate;
              cursor: pointer;

              &:before {
                content: '+';
                margin-right: 1px;
              }
            }
          }
        }
      }
    }
  }

  @keyframes event-more {
    100% {
      transform: scale(0.8);
    }
  }

  #app[data-theme^='dark'] {
    .mGameInfo {
      table {
        background-color: #585858;
        th {
          background-color: #696969;
          color: #ffffff;
        }
        td {
          &.round-block {
            border-bottom: 1px solid #5f5f5f;
            border-right: 1px solid #5f5f5f;
          }
          .team-block {
            .team {
              color: #ffffff;
            }
            .info-row {
              color: #fff;

              .event-live {
                &:hover,
                &:active {
                  filter: brightness(1);
                }
              }
              .event-more {
                color: #fff;
              }
            }
          }
        }
      }
      &.closed::after {
        background-color: #4f4f4f;
      }
    }
  }
</style>
