<template>
  <div id="mGamesBetInfoAll" @click.stop="onMaskClick">
    <div class="popup">
      <div v-if="panelMode === 1" class="header">
        <ul class="tab-list">
          <li class="tab-item" :class="tabIndex === 0 ? 'active' : ''" @click="tabIndex = 0">
            {{ $t('Common.SingleItem') }}
            <span class="number">
              {{ betCartList.length }}
            </span>
          </li>
          <li class="tab-item" :class="tabIndex === 1 ? 'active' : ''" @click="tabIndex = 1">
            {{ $t('Common.Stray') }}
            <span class="number">
              {{ betCartList.length }}
            </span>
          </li>
        </ul>
        <div class="userCredit"> ${{ toCurrency(userBalance) }} </div>
        <img
          class="btn-close"
          src="@/assets/img/mobile/btn_arrow_w.svg"
          @click.stop="onMaskClick"
        />
      </div>
      <div v-else class="header" style="align-items: center">
        <div style="font-size: 16px; flex-grow: 1; text-align: center">
          {{ $t('Common.BetList') }}
        </div>
        <img
          class="btn-close"
          src="@/assets/img/mobile/btn_arrow_w.svg"
          @click.stop="onMaskClick"
        />
      </div>
      <div class="body" ref="body">
        <BetViewList :groupIndex="0" :childIndex="tabIndex" @toTop="scrollToTop" />
      </div>
    </div>
    <div class="bg" />
  </div>
</template>

<script>
  import BetViewList from '@/components/BetViewList';
  import { mapState } from 'vuex';
  import { toCurrency } from '@/config/balance';

  export default {
    components: {
      BetViewList,
    },
    data() {
      return {
        tabIndex: 0,
      };
    },
    mounted() {
      this.scrollToBottom();
    },
    computed: {
      ...mapState(['userBalance']),
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      panelMode() {
        return this.$store.state.BetCart.panelMode;
      },
    },
    methods: {
      toCurrency,
      onMaskClick(e) {
        if (e.target !== e.currentTarget) return;
        this.$emit('onCloseBetInfo');
      },
      scrollToBottom() {
        const view = this.$refs.body;
        view.scrollTo({ top: view.scrollHeight });
      },
      scrollToTop() {
        const view = this.$refs.body;
        view.scrollTo({ top: 0 });
      },
    },
    watch: {
      betCartList(list) {
        list.length === 0 && this.$emit('onCloseBetInfo');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #app[data-theme^='light'] {
    #mGamesBetInfoAll {
      .body {
        background-color: #d4d4d4;
      }
    }
  }
  #app[data-theme^='dark'] {
    #mGamesBetInfoAll {
      .body {
        background-color: #666666;
      }
    }
  }
  #mGamesBetInfoAll {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgba(0, 0, 0, 0.5);

    width: 100%;
    height: 100%;
    z-index: 18;

    .bg {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 19;
    }

    .popup {
      display: flex;
      flex-flow: column nowrap;
      position: fixed;
      width: calc(100vw - 50px);
      z-index: 20;
      max-width: 560px;
      max-height: 70%;

      .header {
        flex-shrink: 0;
        display: flex;
        @include base-background();
        // background-color: #3fa381;
        color: #fff;
        border-radius: 4px 4px 0 0;
        font-size: 0.95em;
        min-height: 30px;

        ul.tab-list {
          display: flex;
          max-width: 60%;
          overflow: auto;
          li.tab-item {
            min-height: 26px;
            padding: 0 8px;
            min-width: 60px;
            text-align: center;
            line-height: 26px;
            border-radius: 3px;
            margin: 7px 5px;
            cursor: pointer;
            background-color: transparent;
            font-size: 1.25rem;

            &.active,
            &:active {
              background-color: #fff;
              color: #6da9e5;
            }

            span.number {
              margin-left: 5px;
              color: #ffbc00;
            }
          }
        }

        .userCredit {
          font-size: 1rem;
          font-weight: bold;
          margin-left: auto;
          align-self: center;
          margin-right: 15px;
        }

        .btn-close {
          opacity: 0.7;
          width: 16px;
          margin-right: 15px;
        }
      }
      .body {
        flex-grow: 1;

        padding: 5px;
        padding-bottom: 10px;
        border-radius: 0 0 4px 4px;
        overflow: auto;
      }
    }
  }
</style>
