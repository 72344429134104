<template>
  <div id="ModifyPWD">
    <PersonalPanel :type="2" :isSingleMode="true" :expire="true" @closeMe="closeMe" />
  </div>
</template>

<script>
  import PersonalPanel from '@/components/PersonalPanel';

  export default {
    components: {
      PersonalPanel,
    },
    methods: {
      closeMe() {
        this.$notify({
          type: 'success',
          title: this.$t('Common.Success'),
          message: this.$t('common.modifyPWD_SUCCESS'),
        });
        this.$router.replace({ name: 'Login' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #ModifyPWD {
    width: 100%;
    height: 100%;
    background-color: #1a2b3c;
  }
</style>
