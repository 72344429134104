<template>
  <div id="m-header">
    <div class="left">
      <img
        v-if="page !== PageEnum.game"
        class="goBackBtn"
        src="@/assets/img/mobile/btn_arrow_w.svg"
        @click="$emit('goPage', PageEnum.game)"
      />
      <h5 v-if="pageName" class="pageName">{{ pageName }}</h5>
      <template v-if="page === PageEnum.game">
        <ul class="gameTypeNav">
          <li
            v-for="(item, i) in gameTypeList"
            :key="i"
            class="navItem"
            :class="gameTypeID === item.key ? 'active' : ''"
            @click="showType(item.key)"
          >
            <div class="text">{{ item.value }}</div>
          </li>
        </ul>
      </template>
    </div>
    <div class="right">
      <div class="userCreditBlock">
        <div v-if="userBalance" class="creditText">${{ toCurrency(userBalance) }}</div>
      </div>
      <div v-if="!siteLabel" class="service">
        <img
          src="@/assets/img/common/icon_header_service.svg"
          class="icon-service"
          @click="openService()"
        />
        <div v-show="userUnread > 0" class="unreadMark">{{ userUnread }}</div>
      </div>
      <img class="icon-more" src="@/assets/img/mobile/icon_more.svg" @click="openMenuPanel()" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { PageEnum } from '@/config/mobile/enum';
  import { toCurrency } from '@/config/balance';

  export default {
    props: {
      page: {
        type: Number,
        default: PageEnum.game,
      },
    },
    data() {
      return {
        PageEnum,
      };
    },
    computed: {
      ...mapState(['siteLabel', 'userBalance', 'userUnread']),
      pageName() {
        switch (this.page) {
          case PageEnum.gameResult:
            return this.$t('GamesHeader.GameResult');
          case PageEnum.announcement:
            return this.$t('Ann.Title');
          case PageEnum.liveScore:
            return this.$t('GamesHeader.LiveScore');
          case PageEnum.rules:
            return this.$t('GamesHeader.GameRule');
          case PageEnum.strayCounter:
            return this.$t('GamesBetInfo.StrayCount');
          case PageEnum.news:
            return this.$t('GamesHeader.news');
          default:
            return '';
        }
      },
      GAME_FAVORITE_CAT() {
        return this.$conf.GAME_FAVORITE_CAT;
      },
      gameStore() {
        return this.$store.state.Game;
      },
      gameTypeID() {
        return this.gameStore.selectGameType;
      },
      gameTypeList() {
        const { GameTypeList } = this.gameStore;
        return GameTypeList.filter(({ key }) => key >= 2);
      },
    },
    methods: {
      toCurrency,
      showType(type) {
        const { GAME_FAVORITE_CAT, selectGameType, selectCatID, selectWagerTypeKey } = this;
        if (type === selectGameType) return;

        let catID = null;
        let wagerKey = null;
        if (selectCatID === GAME_FAVORITE_CAT) {
          catID = selectCatID;
        } else {
          const menuData = this.gameStore.FullMenuList.find(
            (menuData) => menuData.GameType === type
          );
          if (menuData) {
            const { item: catList } = menuData.LeftMenu;
            if (catList?.length) {
              const catItem = catList.find((catItem) => catItem.catid === selectCatID);
              if (catItem) {
                catID = selectCatID;
                const { Items: wagerList } = catItem;
                if (wagerList?.length) {
                  const wagerItem = wagerList.find(
                    (wagerItem) => wagerItem.WagerTypeKey === selectWagerTypeKey
                  );
                  if (wagerItem) {
                    wagerKey = selectWagerTypeKey;
                  } else {
                    wagerKey = wagerList[0].WagerTypeKey;
                  }
                }
              } else {
                const catData0 = catList[0];
                catID = catData0.catid;
                const { Items: wagerList } = catData0;
                if (wagerList?.length) {
                  wagerKey = wagerList[0].WagerTypeKey;
                }
              }
            }
          }
        }
        if (catID === null) {
          catID = 1;
          wagerKey = 1;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: type,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });

        window.OddData.clear();
        this.$store.commit('MoreGame/closeMoreGameList');
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('Game/updateGameDetail', { clear: true })
          .then(() => {
            this.$store.dispatch('Game/updateGameMenuList');
            this.$store.commit('Game/changeCatReset', selectCatID);
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      openService() {
        this.$emit('openService');
      },
      openMenuPanel() {
        this.$emit('openMenuPanel');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #m-header {
    @include base-background();
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    padding: 4px 10px;
    border-bottom: 1px solid #ccc;

    .left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .goBackBtn {
        width: 2rem;
        height: auto;
        transform: rotate(90deg);
        opacity: 0.7;
        margin-right: 1rem;
        cursor: pointer;
        &:active {
          opacity: 1;
        }
      }

      .pageName {
        font-size: 1.6rem;
        font-weight: normal;
        line-height: normal;
        color: #fff;
        margin: 0;
      }

      ul.gameTypeNav {
        display: flex;
        align-items: center;
        // margin-left: 1.2rem;
        li.navItem {
          display: flex;
          align-items: center;
          height: 2rem;
          padding: 0px 0.8rem;
          color: white;
          margin-right: 1.4rem;
          border-radius: 2px;
          .text {
            text-align: center;
            margin-top: 2px;
            font-size: 1.2rem;
            white-space: nowrap;
          }
          &:last-child {
            margin-right: 0px;
          }
          &.active {
            background-color: #fff;
            color: #6da9e5;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: auto;
      .userCreditBlock {
        .creditText {
          color: #ffdf1a;
          font-size: 1.2rem;
          white-space: nowrap;
        }
      }
      img.icon-more,
      img.icon-logout,
      img.icon-service {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        margin-left: 1.25rem;
        opacity: 0.8;
        &:active {
          opacity: 1;
        }
      }

      div.service {
        position: relative;
        .unreadMark {
          position: absolute;
          top: -6px;
          left: calc(100% - 10px);
          min-width: 20px;
          min-height: 20px;
          padding: 3px 6px;
          background-color: hsla(0, 55%, 52%, 0.9);
          border-radius: 50rem;
          color: #fff;
          white-space: nowrap;
          display: flex;
          align-items: center;
          overflow: hidden;
          line-height: normal;
          z-index: 5;
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    ul.gameTypeNav {
      li.navItem {
        &.active {
          color: #ffffff;
          background-color: #5298e8;
        }
      }
    }
  }

  #app[data-i18n^='vi'] {
    #m-header {
      padding: 0 8px;
      .left {
        ul.gameTypeNav {
          li.navItem {
            padding: 0 4px;
            margin-right: 2px;
            .text {
              font-size: 14px;
            }
          }
        }
      }
      .right {
        .userCreditBlock {
          .creditText {
            color: #ffdf1a;
            font-size: 16px;
            white-space: nowrap;
          }
        }
        img.icon-more,
        img.icon-logout,
        img.icon-service {
          width: 18px;
          height: 18px;
          margin-left: 4px;
        }
        div.service {
          .unreadMark {
            top: -6px;
            left: calc(100% - 10px);
            min-width: 20px;
            min-height: 20px;
            padding: 3px 6px;
          }
        }
      }
    }
  }
</style>
