<template>
  <div
    v-if="panelMode === PanelModeEnum.result && cartData.betResult !== null"
    class="betResultBlock"
  >
    <div v-if="cartData.betResult.code === 200" class="success">
      <img class="betResultImgIcon" src="@/assets/img/common/betView/ok.png" />
      {{ cartData.betResult.Message }}
      <span
        v-if="
          cartData.betResult.PayoutOdds &&
          cartData.winAmount !==
            $lib.trunc(parseFloat(cartData.betResult.PayoutOdds) * cartData.betAmount)
        "
        style="display: inline-block; padding-left: 4px; color: red"
      >
        已接收最佳賠率 {{ showOddValue2(cartData.betResult.PayoutOdds) }}
      </span>
    </div>
    <div v-else-if="cartData.betResult.code === 201" class="info">
      <img class="betResultImgIcon" src="@/assets/img/common/betView/info.png" />
      {{ cartData.betResult.Message }}
    </div>
    <div v-else-if="cartData.betResult.code === 202" class="error">
      <img class="betResultImgIcon" src="@/assets/img/common/betView/info.png" />
      {{ cartData.betResult.Message }}
    </div>
    <div v-else-if="cartData.betResult.code < 0" class="error">
      <img class="betResultImgIcon" src="@/assets/img/common/betView/error.png" />
      {{ cartData.betResult.Message }}
    </div>
  </div>
</template>

<script>
  import { PanelModeEnum } from '@/config/bet';

  export default {
    props: {
      panelMode: {
        type: Number,
      },
      cartData: {
        type: Object,
      },
    },
    data() {
      return {
        PanelModeEnum,
      };
    },
    computed: {
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
    },
    methods: {
      showOddValue2(oddValue) {
        if (this.includePrincipal) {
          return this.$lib.toDecimal(this.$lib.trunc(parseFloat(oddValue) + 1));
        } else {
          return this.$lib.toDecimal(this.$lib.trunc(oddValue));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #app[data-theme^='light'] {
    .betResultBlock {
      .success {
        color: green;
      }
      .info {
        color: blue;
      }
      .error {
        color: red;
      }
    }
  }

  #app[data-theme^='dark'] {
    .betResultBlock {
      .success {
        color: #148800;
      }
      .info {
        color: #005aff;
      }
      .error {
        color: #ff8686;
      }
    }
  }
  .betResultBlock {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .betResultImgIcon {
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    .success,
    .info,
    .error {
      display: flex;
      align-items: center;
    }
  }
</style>
