export const PageEnum = Object.freeze({
  game: 1, // 主頁
  gameResult: 2, // 賽果
  announcement: 3, // 公告
  liveScore: 4, // 即時比分
  strayCounter: 5, // 過關計算器
  news: 6, // 新聞
  rules: 7, // 規則
  promotion: 8, // 世界盃
});
