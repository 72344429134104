var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    moving: _vm.isMoving,
    resizing: _vm.resing,
    min: _vm.showMode === _vm.minMode,
    max: _vm.showMode === _vm.maxMode,
    full: _vm.showMode === _vm.fullscreenMode,
  },attrs:{"id":"standalone-live"}},[(_vm.isMoving)?_c('div',{staticClass:"live-bg"}):_vm._e(),_c('div',{staticClass:"live-bar"},[_c('div',{staticClass:"live-title",on:{"mousedown":function($event){$event.stopPropagation();return _vm.beginMoving($event)},"touchstart":function($event){$event.stopPropagation();return _vm.beginMoving($event)},"click":function($event){$event.stopPropagation();return _vm.restoreRect()}}},[_c('img',{staticClass:"title-icon",attrs:{"src":require("@/assets/img/common/btn_GDV_live.svg")}}),_c('span',{staticClass:"title-name"},[_vm._v(_vm._s(_vm.standaloneName))])]),_c('div',{staticClass:"live-buttons"},[_c('img',{staticClass:"button minimize",attrs:{"src":require("@/assets/live/minimize.svg"),"title":_vm.$t('live.min')},on:{"click":function($event){$event.stopPropagation();return _vm.minimize()}}}),_c('img',{staticClass:"button maximize",attrs:{"src":require("@/assets/live/maximize.svg"),"title":_vm.$t('live.max')},on:{"click":function($event){$event.stopPropagation();return _vm.maximize()}}}),(_vm.isSupportFullscreen)?_c('img',{staticClass:"button fullscreen",attrs:{"src":require("@/assets/live/fullscreen.svg"),"title":_vm.$t('live.fullscreen')},on:{"click":function($event){$event.stopPropagation();return _vm.fullscreen()}}}):_vm._e(),_c('img',{staticClass:"button close",attrs:{"src":require("@/assets/live/close.svg"),"title":_vm.$t('live.close')},on:{"click":function($event){$event.stopPropagation();return _vm.close()}}})])]),_c('iframe',{ref:"liveVideoIframeRef",staticClass:"live-iframe",attrs:{"src":_vm.standaloneUrl}}),_c('div',{staticClass:"resize-helper",on:{"mousedown":function($event){$event.stopPropagation();return _vm.beginResizing($event)},"touchstart":function($event){$event.stopPropagation();return _vm.beginResizing($event)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }