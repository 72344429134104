<template>
  <div id="odds-history" class="page">
    <main class="container">
      <header class="title">
        <span v-if="home && away">{{ home + ' vs ' + away }}</span>
        盤口水位歷程
      </header>
      <section class="content">
        <div id="my-odds-chart" ref="oddsChart" class="chart" />
      </section>
    </main>
  </div>
</template>

<script>
  import { apiGetOddsHistory } from '@/api/game';
  import * as echarts from 'echarts';

  export default {
    data() {
      return {
        home: '',
        away: '',
        oddsChart: null,
      };
    },
    computed: {
      query() {
        return this.$route.query;
      },
    },
    watch: {
      query() {
        this.updateChart();
      },
    },
    methods: {
      updateChart() {
        if (!this.oddsChart) return;

        this.oddsChart.clear();

        const { GameID, WagerPos } = this.$route.query;
        if (!GameID || !WagerPos) return;

        apiGetOddsHistory({
          GameID,
          WagerPos,
        })
          .then((response) => {
            const { Logs, Limit, HomeTeam, AwayTeam } = response.data;
            const { Odds, hdp } = Limit;

            this.home = HomeTeam;
            this.away = AwayTeam;

            const playingIndex = Logs.findIndex((item) => item.GameType === 2);
            let markArea = [];
            if (playingIndex !== -1) {
              markArea = [
                [
                  {
                    xAxis: playingIndex,
                  },
                  {
                    xAxis: 'max',
                  },
                ],
              ];
            }

            let headMarkPointData = [];

            let oddsMarkPointData = [];
            if (Odds.Min === '') {
              oddsMarkPointData = [{ type: 'max', name: 'Max', itemStyle: { color: '#f00' } }];
            } else {
              oddsMarkPointData = [
                { type: 'max', name: 'Max', itemStyle: { color: '#f00' } },
                { type: 'min', name: 'Min', itemStyle: { color: '#0f0' } },
              ];
            }

            let option = {};

            if (hdp) {
              if (hdp.Min === '') {
                headMarkPointData = [{ type: 'max', name: 'Max', itemStyle: { color: '#f00' } }];
              } else {
                headMarkPointData = [
                  { type: 'max', name: 'Max', itemStyle: { color: '#f00' } },
                  { type: 'min', name: 'Min', itemStyle: { color: '#0f0' } },
                ];
              }

              option = {
                title: {
                  text: '',
                },
                dataset: {
                  source: Logs,
                  dimensions: ['LogTimeStr', 'Odds', 'HdpVal', 'Hdp'],
                },
                grid: [
                  {
                    left: '10%',
                    right: '10%',
                    top: '80',
                    height: '160',
                  },
                  {
                    left: '10%',
                    right: '10%',
                    top: '320',
                    height: '160',
                  },
                ],
                xAxis: [
                  {
                    gridIndex: 0,
                    name: '',
                    type: 'category',
                    min: 'dataMin',
                    max: 'dataMax',
                    boundaryGap: false,
                    axisLine: { show: true, onZero: false },
                    axisTick: { show: true },
                    axisLabel: { show: false },
                    axisPointer: {
                      z: 100,
                    },
                    splitLine: { show: false },
                    splitArea: { show: false },
                  },
                  {
                    gridIndex: 1,
                    name: '時間',
                    type: 'category',
                    min: 'dataMin',
                    max: 'dataMax',
                    boundaryGap: false,
                    axisLine: { show: true, onZero: false },
                    axisTick: { show: true },
                    axisLabel: { show: true },
                    axisPointer: {
                      z: 100,
                    },
                    splitLine: { show: false },
                    splitArea: { show: false },
                  },
                ],
                yAxis: [
                  {
                    gridIndex: 0,
                    name: '盤口',
                    nameLocation: 'end',
                    min: function (value) {
                      if (value.max === value.min) {
                        if (value.min === 0) return -1;
                        return value.min - value.min / 10;
                      }
                      return value.min - (value.max - value.min);
                    },
                    max: function (value) {
                      if (value.max === value.min) {
                        if (value.max === 0) return 1;
                        return value.max + value.max / 10;
                      }
                      return value.max + (value.max - value.min);
                    },
                    axisLine: { show: true },
                    axisTick: { show: true },
                    axisLabel: {
                      show: true,
                      formatter: function (value) {
                        return value.toFixed(2);
                      },
                    },
                    axisPointer: {
                      snap: true,
                    },
                    splitLine: { show: true },
                    splitArea: { show: false },
                    splitNumber: 3,
                    scale: false,
                    offset: 32,
                  },
                  {
                    gridIndex: 1,
                    name: '賠率',
                    nameLocation: 'end',
                    min: function (value) {
                      if (value.max === value.min) {
                        return value.min - value.min / 10;
                      }
                      return value.min - (value.max - value.min);
                    },
                    max: function (value) {
                      if (value.max === value.min) {
                        return value.max + value.max / 10;
                      }
                      return value.max + (value.max - value.min);
                    },
                    axisLine: { show: true },
                    axisTick: { show: true },
                    axisLabel: {
                      show: true,
                      formatter: function (value) {
                        return value.toFixed(3);
                      },
                    },
                    axisPointer: {
                      snap: true,
                    },
                    splitLine: { show: true },
                    splitArea: { show: false },
                    scale: false,
                    splitNumber: 3,
                    offset: 32,
                  },
                ],
                series: [
                  {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    name: '盤口',
                    type: 'line',
                    dimensions: ['LogTimeStr', 'HdpVal', 'Hdp'],
                    color: '#00f',
                    showAllSymbol: 'auto',
                    label: {
                      show: true,
                      formatter: '{@Hdp}',
                    },
                    labelLayout: {
                      hideOverlap: true,
                    },
                    markPoint: {
                      data: headMarkPointData,
                      label: {
                        show: true,
                        formatter: (params) => {
                          const { data } = params;
                          const { coord } = data;
                          return Logs[coord[0]].Hdp;
                        },
                      },
                      symbolOffset: [0, -20],
                    },
                    markArea: {
                      itemStyle: {
                        color: 'rgba(255, 173, 177, 0.4)',
                      },
                      data: markArea,
                    },
                  },
                  {
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    name: '賠率',
                    type: 'line',
                    dimensions: ['LogTimeStr', 'Odds'],
                    color: '#f00',
                    label: {
                      show: true,
                    },
                    labelLayout: {
                      hideOverlap: true,
                    },
                    markPoint: {
                      data: oddsMarkPointData,
                      symbolOffset: [0, -20],
                    },
                    markArea: {
                      itemStyle: {
                        color: 'rgba(255, 173, 177, 0.4)',
                      },
                      data: markArea,
                    },
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                  },
                  formatter: function (params) {
                    // console.log(params);
                    params.sort((a, b) => a.seriesIndex - b.seriesIndex);
                    const [head, odds] = params;
                    return `${head.data.LogTimeStr}<br/>${head.marker} ${head.seriesName} : ${head.data.Hdp}<br />${odds.marker} ${odds.seriesName} : ${odds.data.Odds}`;
                  },
                },
                axisPointer: {
                  link: [
                    {
                      xAxisIndex: 'all',
                    },
                  ],
                },
                dataZoom: [
                  {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 0,
                    end: 100,
                  },
                  {
                    type: 'slider',
                    xAxisIndex: [0, 1],
                    show: true,
                    start: 0,
                    end: 100,
                  },
                ],
              };
            } else {
              option = {
                title: {
                  text: '',
                },
                dataset: {
                  source: Logs,
                  dimensions: ['LogTimeStr', 'Odds'],
                },
                grid: [
                  {
                    left: '10%',
                    right: '10%',
                    top: '80',
                    height: '160',
                  },
                ],
                xAxis: [
                  {
                    name: '',
                    type: 'category',
                    min: 'dataMin',
                    max: 'dataMax',
                    boundaryGap: false,
                    axisLine: { show: true, onZero: false },
                    axisTick: { show: true },
                    axisLabel: { show: true },
                    axisPointer: {
                      z: 100,
                    },
                    splitLine: { show: false },
                    splitArea: { show: false },
                  },
                ],
                yAxis: [
                  {
                    name: '賠率',
                    nameLocation: 'end',
                    min: function (value) {
                      if (value.max === value.min) {
                        return value.min - value.min / 10;
                      }
                      return value.min - (value.max - value.min);
                    },
                    max: function (value) {
                      if (value.max === value.min) {
                        return value.max + value.max / 10;
                      }
                      return value.max + (value.max - value.min);
                    },
                    axisLabel: {
                      show: true,
                      formatter: function (value) {
                        return value.toFixed(3);
                      },
                    },
                    axisLine: { show: true },
                    axisPointer: {
                      snap: true,
                    },
                    splitLine: { show: true },
                    splitArea: { show: false },
                    scale: false,
                    splitNumber: 3,
                    offset: 32,
                  },
                ],
                series: [
                  {
                    name: '賠率',
                    type: 'line',
                    dimensions: ['LogTimeStr', 'Odds'],
                    color: '#f00',
                    label: {
                      show: true,
                    },
                    labelLayout: {
                      hideOverlap: true,
                    },
                    markPoint: {
                      data: oddsMarkPointData,
                      symbolOffset: [0, -20],
                    },
                    markArea: {
                      itemStyle: {
                        color: 'rgba(255, 173, 177, 0.4)',
                      },
                      data: markArea,
                    },
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                  },
                  formatter: function (params) {
                    const [odds] = params;
                    return `${odds.data.LogTimeStr}<br/>${odds.marker} ${odds.seriesName} : ${odds.data.Odds}`;
                  },
                },
                axisPointer: {
                  link: [
                    {
                      xAxisIndex: 'all',
                    },
                  ],
                },
                dataZoom: [
                  {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 0,
                    end: 100,
                  },
                  {
                    type: 'slider',
                    xAxisIndex: [0, 1],
                    show: true,
                    start: 0,
                    end: 100,
                  },
                ],
              };
            }

            this.oddsChart.setOption(option);
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
    mounted() {
      if (this.$refs.oddsChart) {
        this.oddsChart = echarts.init(this.$refs.oddsChart);
      }
      this.updateChart();
    },
    beforeDestroy() {
      if (this.oddsChart) {
        this.oddsChart.dispose();
        this.oddsChart = null;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .title {
    width: 100%;
    height: 64px;
    background-color: rgba(56, 166, 136, 1);
    line-height: 64px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    .title {
      font-size: 16px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 64px);
  }

  .chart {
    width: 100%;
    height: 100%;
    min-height: 80px;
    padding: 16px;
  }
</style>
