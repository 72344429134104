<template>
  <div id="mBetRecordView">
    <div class="header-container">
      <div class="title">
        {{ $t('HistoryRecord.BetHistoryRecord') }}
      </div>
      <div v-if="tabIndex === 1" class="left-btns">
        <div v-if="isShowGoBackBtn" class="btn-back" @click="$refs.RecordHistory.goBackPage()">
          <i class="el-icon-arrow-left" />
        </div>
        <div class="btn-refresh" @click="$refs.RecordHistory.refresh()">
          <i class="el-icon-refresh-right" />
        </div>
      </div>
      <div class="btn-close" @click="$emit('onCloseBetRecordView')">
        <i class="el-icon-close" />
      </div>
    </div>
    <ul class="navList">
      <li :class="{ active: tabIndex === 0 }" @click="onTabClick(0)">
        <div class="text">
          {{ $t('Common.NotCount') }}
          <span class="count">
            {{ betHistoryListCount }}
          </span>
        </div>
      </li>
      <li :class="{ active: tabIndex === 1 }" @click="onTabClick(1)">
        <div class="text">
          {{ $t('Common.IsCount') }}
        </div>
      </li>
    </ul>
    <div class="main-container">
      <div v-if="tabIndex === 0" style="padding: 10px; height: 100%">
        <BetViewList :groupIndex="1" :childIndex="tabIndex" />
        <div id="BetViewListPage">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageData.CurrentPage"
            :page-sizes="[101]"
            :page-size="pageData.PageSize"
            :total="betHistoryListCount"
            layout="total, prev, pager, next"
          />
        </div>
      </div>
      <mBetRecordHistory
        v-else-if="tabIndex === 1"
        ref="RecordHistory"
        @showGoBackBtn="(visible) => (isShowGoBackBtn = visible)"
      />
    </div>
  </div>
</template>

<script>
  import BetViewList from '@/components/BetViewList';
  import mBetRecordHistory from './mBetRecordHistory';

  export default {
    name: 'mBetRecordView',
    components: {
      BetViewList,
      mBetRecordHistory,
    },
    data() {
      return {
        tabIndex: 0,
        isShowGoBackBtn: false,
        pageData: {
          CurrentPage: 1,
          TotalPage: 1,
          PageSize: 51,
        },
      };
    },
    computed: {
      betHistoryListCount() {
        return this.$store.state.BetCart.betHistoryListCount;
      },
    },
    methods: {
      onTabClick(index) {
        if (this.tabIndex === index) {
          this.apiGetTicket();
        } else {
          this.tabIndex = index;
        }
      },
      apiGetTicket() {
        this.$store.commit('setLoading', true);
        let postData = {};
        postData = {
          page: this.pageData.CurrentPage,
          pagesize: this.pageData.PageSize,
        };
        this.$store
          .dispatch('BetCart/apiGetTicket', {
            isset: false,
            ...postData,
          })
          .then((res) => {
            this.betHistoryData = res.data.list;
            this.pageData.CurrentPage = res.data.CurrentPage;
            this.pageData.TotalPage = res.data.TotalPage;
            this.pageData.PageSize = res.data.PageSize;
            this.pageData.Count = res.data.Count;
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      handleSizeChange(val) {
        this.pageData.CurrentPage = 1;
        this.pageData.PageSize = val;
        this.apiGetTicket();
      },
      handleCurrentChange(val) {
        this.pageData.CurrentPage = val;
        this.apiGetTicket();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #mBetRecordView {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .header-container {
      @include base-background();
      width: 100%;
      height: 45px;
      padding: 0 10px;
      border-bottom: 1px solid #ccc;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 1.3rem;
        text-align: center;
        color: #fff;
      }

      .btn-close {
        position: absolute;
        right: 1rem;
        cursor: pointer;

        i {
          font-size: 2rem;
          color: #fff;
        }
      }

      .left-btns {
        position: absolute;
        left: 1rem;
        display: flex;

        .btn-back,
        .btn-refresh {
          margin-right: 1rem;
          cursor: pointer;

          i {
            font-size: 2rem;
            color: #fff;
          }
        }
      }
    }

    ul.navList {
      @include base-background();
      height: 40px;
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: center;

      li {
        padding: 3px;
        font-size: 1.25rem;
        text-align: center;
        color: #fff;
        cursor: pointer;
        flex: 1;

        .text {
          position: relative;
          width: 60%;
          margin: 0 auto;

          .count {
            position: absolute;
            top: 0;
            right: 0;
            min-height: 18px;
            min-width: 18px;
            padding: 0 6px;
            border-radius: 10em;
            font-size: 12px;
            line-height: 1;
            background: rgb(255 40 40 / 80%);
            display: flex;
            align-items: center;
          }
        }

        &.active {
          .text {
            padding-bottom: 3px;
            border-bottom: 3px solid #fff;
            display: inline-block;
          }
        }
      }
    }

    .main-container {
      position: relative;
      background-color: #d8d8d8;
      overflow: auto;
      flex: auto;

      & * {
        user-select: text !important;
      }
    }

    #BetViewListPage {
      position: absolute;
      bottom: 8px;
      width: 92%;
      text-align: center;
    }
  }

  #app[data-theme^='dark'] {
    #mBetRecordView {
      .main-container {
        background-color: #404040;
      }
    }
  }
</style>
