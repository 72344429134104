<template>
  <div id="GamesBetInfo">
    <div class="topHeader">
      <div class="topHeaderTextItemGroup">
        <div
          class="topHeaderTextItem"
          @click="
            selectGroupIndex = 0;
            selectChildIndex = 0;
          "
        >
          <div class="topHeaderTextItemChild" :class="topHeaderTextItemChildCSS(0)">
            {{ $t('GamesBetInfo.BetInfo') }}
          </div>
          <div class="topHeaderTextItemChild SelectBorder" v-if="this.selectGroupIndex === 0"></div>
        </div>
        <div
          class="topHeaderTextItem"
          @click="
            selectGroupIndex = 1;
            selectChildIndex = 0;
          "
        >
          <div class="topHeaderTextItemChild" :class="topHeaderTextItemChildCSS(1)">
            {{ $t('GamesBetInfo.NewestBet') }}
          </div>
          <div class="topHeaderTextItemChild SelectBorder" v-if="this.selectGroupIndex === 1"></div>
        </div>
      </div>
      <div class="topHeaderIconItemGroup">
        <div
          class="topHeaderIconItem"
          @click="$emit('openStrayCount')"
          :title="$t('GamesBetInfo.StrayCount')"
        >
          <img src="@/assets/img/pc/btn_count.svg" alt="" />
        </div>
        <!--<div
          class="topHeaderIconItem"
          @click="$emit('openSetting')"
          :title="$t('GamesBetInfo.Setting')"
        >
          <img src="@/assets/img/pc/btn_funSet.svg" alt="" />
        </div> -->
      </div>
    </div>
    <div class="bottomContainer">
      <div
        class="bottomHeaderRow"
        v-if="(selectGroupIndex === 0 && showBetCartList.length !== 0) || selectGroupIndex === 1"
      >
        <div
          class="bottomHeaderRowItem"
          :class="selectChildIndex === 0 ? 'bottomHeaderRowItemActive' : ''"
          @click="selectChildIndex = 0"
        >
          {{ bottomHeaderRowItem(0) }}
          <span class="count" v-if="selectGroupIndex === 1">
            {{ betHistoryListCount }}
          </span>
        </div>
        <div
          class="bottomHeaderRowItem"
          :class="selectChildIndex === 1 ? 'bottomHeaderRowItemActive' : ''"
          @click="selectChildIndex = 1"
        >
          {{ bottomHeaderRowItem(1) }}
        </div>
      </div>

      <BetViewList
        :groupIndex="selectGroupIndex"
        :childIndex="selectChildIndex"
        @setNewGroupIndex="setNewGroupIndex"
        @setNewChildIndex="setNewChildIndex"
      >
      </BetViewList>
    </div>
  </div>
</template>

<script>
  import BetViewList from '@/components/BetViewList';

  export default {
    components: {
      BetViewList,
    },
    data() {
      return {
        selectGroupIndex: 0,
        selectChildIndex: 0,
        lastCartLength: 0,
      };
    },
    computed: {
      showBetCartList() {
        return this.$store.getters['BetCart/showBetCartList'];
      },
      showBetHistoryList() {
        return this.$store.getters['BetCart/showBetHistoryList'];
      },
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      autoSwitchToStrayMode() {
        return this.$store.state.Setting.UserSetting.autoSwitchToStrayMode;
      },
      betHistoryListCount() {
        return this.$store.state.BetCart.betHistoryListCount;
      },
    },
    watch: {
      betCartList: {
        handler() {
          if (this.selectGroupIndex === 0 && this.lastCartLength < this.betCartList.length) {
            // 如果購物車數量<=1時,強制切換成單項下注
            if (this.betCartList.length <= 1) {
              this.selectChildIndex = 0;
            } else {
              if (this.autoSwitchToStrayMode) {
                this.selectChildIndex = 1;
              } else {
                this.selectChildIndex = 0;
              }
            }
          }
          this.lastCartLength = this.betCartList.length;
        },
      },
    },
    methods: {
      bottomHeaderRowItem(index) {
        if (this.selectGroupIndex === 0) {
          if (index === 0) {
            return this.$t('GamesBetInfo.NormalBet');
          } else {
            return this.$t('GamesBetInfo.StrayBet');
          }
        } else {
          if (index === 0) {
            return this.$t('GamesBetInfo.NotCountBet');
          } else {
            return this.$t('GamesBetInfo.isCountBet');
          }
        }
      },
      topHeaderTextItemChildCSS(groupIndex) {
        if (groupIndex === this.selectGroupIndex) {
          return 'ItemTextActive';
        } else {
          return '';
        }
      },
      resetGroupIndex() {
        if (this.selectGroupIndex !== 0) {
          this.selectGroupIndex = 0;
          this.selectChildIndex = 0;
        }
      },
      setNewGroupIndex(val) {
        this.selectGroupIndex = val;
      },
      setNewChildIndex(val) {
        this.selectChildIndex = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  .topHeader {
    @include nav-headrtBgcolor();
  }
  #app[data-theme^='light'] {
    #GamesBetInfo {
      background-color: #d5d5d5;
      .topHeader {
        //background-color: #136146;
        //border-bottom-color: #136146;
        .SelectBorder {
          background-color: #caffed;
        }
      }
      .bottomHeaderRowItem {
        background-color: #c5c5c5;
      }
      .bottomHeaderRowItemActive {
        background-color: #f3f3f3;
        color: #000;
      }
    }
  }

  #app[data-theme^='dark'] {
    #GamesBetInfo {
      background-color: #666666;
      .topHeader {
        background-color: #404040;
        border-bottom-color: #333;
        .SelectBorder {
          background-color: #999;
        }
      }
      .bottomHeaderRowItem {
        background-color: #5e5e5e;
        color: white;
      }
      .bottomHeaderRowItemActive {
        color: #000;
        background-color: #bbb;
      }
    }
  }

  #GamesBetInfo {
    width: 300px;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;

    text-align: center;
    .topHeader {
      display: flex;

      height: 35px;
      .topHeaderTextItemGroup {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        .topHeaderTextItem {
          color: white;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          cursor: pointer;
          width: 50%;
          .topHeaderTextItemChild {
            width: 100%;
          }
          .ItemTextActive {
            margin-bottom: -12px;
          }
          .SelectBorder {
            width: 64px;

            height: 3px;
          }
        }
      }
      .topHeaderIconItemGroup {
        display: flex;
        width: 35%;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        .topHeaderIconItem {
          height: fit-content;
          display: flex;
          align-items: center;
          img {
            background-size: 22px;
            height: 22px;
            width: 22px;
            opacity: 0.5;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .bottomContainer {
      padding: 5px;
      width: 100%;
      height: calc(100% - 35px);
      margin-bottom: 5px;
      .bottomHeaderRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        .bottomHeaderRowItem {
          position: relative;
          color: #666;
          width: 49%;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          .count {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            background: rgba(255, 40, 40, 0.8);
            border-radius: 10em;
            min-height: 18px;
            min-width: 18px;
            padding: 0 6px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
          }
        }
      }
    }
  }
</style>
