<template>
  <div id="mWagerTypePopup" @click.stop="close($event)">
    <div class="popup">
      <div class="header">
        <div class="title">{{ $t('Common.ChoosePlay') }}</div>
      </div>
      <div class="line" />
      <div class="body">
        <template v-if="isSub">
          <div
            v-for="(item, index) in groupList"
            :key="index"
            :class="['btn-gameType', { active: item.value === boldFilterType }]"
            @click="showGroup(item.value)"
          >
            <span class="wager-name">{{ item.label }}</span>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(item, index) in wagerTypes"
            :key="index"
            :class="['btn-gameType', { active: selectWagerTypeKey === item.WagerTypeKey }]"
            @click="showWager(item)"
          >
            <span class="wager-name">{{ item.WagerTypeName }}</span>
            <span class="wager-num">{{ item.count }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isSub: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        groupList: [
          { label: this.$t('Common.FullGame'), value: true },
          { label: this.$t('common.halfGame'), value: false },
        ],
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },

      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      CatMapData() {
        return this.$store.state.Game.CatMapData;
      },
      currentCatData() {
        const { selectCatID } = this.gameStore;
        const currentCatData = this.$store.getters['Game/selectMenuCatList'].find(
          (it) => it.catid === selectCatID
        );
        return currentCatData;
      },
      wagerTypes() {
        if (this.currentCatData) {
          return this.currentCatData.Items;
        }
        return [];
      },
      // 是否為足球波膽
      isBoldGame() {
        const { selectCatID, selectWagerTypeKey } = this.gameStore;
        return this.CatMapData[selectCatID].LeftMenuKey2_Key3Model && selectWagerTypeKey === 3;
      },
      // 是否為足球角球
      isCornerGame() {
        const { selectCatID, selectWagerTypeKey } = this.gameStore;
        return this.CatMapData[selectCatID].LeftMenuKey2_Key3Model && selectWagerTypeKey === 2;
      },
      // 全場或半場
      boldFilterType() {
        return this.$store.state.Game.boldFilterType;
      },
      isShowMenu() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 2:
              case 4:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
    },
    methods: {
      showWager(wagerItem) {
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const { WagerTypeKey: wagerKey } = wagerItem;
        if (wagerKey === selectWagerTypeKey) {
          this.$emit('closeWagerTypePopup');
          return;
        }
        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('showLoading');
        this.$store.dispatch('Game/updateGameDetail', { clear: true }).finally(() => {
          this.$store.commit('hideLoading');
        });
        this.$store.commit('Game/setBoldFilterType', true);
        this.$emit('closeWagerTypePopup');
      },
      showGroup(group) {
        this.$store.commit('Game/setBoldFilterType', group);
        this.$emit('closeWagerTypePopup');
      },
      close(event) {
        if (event && event.target !== this.$el) return;
        this.$emit('closeWagerTypePopup');
      },
    },
  };
</script>

<style lang="scss" scoped>
  #mWagerTypePopup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    width: 100%;
    height: 100%;

    .popup {
      width: calc(100vw - 50px);
      max-width: 560px;
      max-height: 80%;
      border: 3px solid #c4ccd7;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .header {
        .title {
          color: #000;
          font-size: 1.5rem;
          text-align: center;
          padding: 1.5rem;
        }
      }
      .line {
        height: 1px;
        background: #ccc;
        margin: 0 1.5rem;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 70%;
        padding: 1.5rem;
        overflow: auto;

        .btn-gameType {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding: 0.65rem 3rem;
          min-height: 3.5rem;
          font-size: 1.3rem;
          color: #000;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 9px;
          cursor: pointer;

          &.active,
          &:active {
            color: #fff;
            background-color: #5198e8;
            border-color: #5198e8;
          }

          .wager-name {
            line-height: 1;
          }
          .wager-num {
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            line-height: 1;
          }
        }
      }
    }
  }
</style>
