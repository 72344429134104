<template>
  <div />
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        isInit: false,
        initEvtID: '',
        isReconnectLock: null,
        timeOutEvent: null,
      };
    },
    created() {
      // toFix
      window.chat = this;
    },
    destroyed() {
      clearTimeout(this.timeOutEvent);
    },
    computed: {
      ...mapState(['sessionToken', 'sessionAccount']),
      chatHost() {
        return this.$store.state.siteInfo?.ChatRoom;
      },

      socketURL() {
        const host = this.$store.state.siteInfo?.ChatRoom;
        return `wss://${host}/roomsocket/roomsocket`;
      },
    },
    methods: {
      reset() {
        this.isInit = false;
        this.initEvtID = '';
        this.lockReconnect();
        this.webSocketObj && this.webSocketObj.close && this.webSocketObj.close();
      },
      initWebsocket(initEvtID, isReconnect = false) {
        this.initEvtID = initEvtID;
        if (this.isInit && !isReconnect) {
          this.changeRoom(initEvtID);
          return;
        }
        this.lockReconnect();
        this.isInit = true;
        this.webSocketObj && this.webSocketObj.close && this.webSocketObj.close();

        this.webSocketObj = new WebSocket(this.socketURL);
        this.webSocketObj.onmessage = this.onMessage;
        this.webSocketObj.onopen = this.onOpen;
        this.webSocketObj.onerror = this.onError;
        this.webSocketObj.onclose = this.onClose;
      },
      onMessage(evt) {
        // console.log(evt);
        if (evt !== undefined) {
          // 其他解析測試
          // a.replace('@','####:####').replace('[','####:####').split("####:####")
          // const formatData = evt.data.split(/@|\[/);
          const formatData = evt.data
            .replace('@', '####:####')
            .replace('[', '####:####')
            .split('####:####');
          const eventName = formatData[1];
          const data = formatData[2];
          // console.log('onMessage:', { formatData, eventName, data });

          switch (eventName) {
            case 'init':
              this.APILoginMB();
              break;
            case 'APINowMes':
              this.$store.commit('Chat/SetChatList', JSON.parse(data).data);
              break;
            case 'APISendMes': {
              const parseData = JSON.parse(data);
              if (parseData && parseData?.data && parseData.data?.length !== 0) {
                this.$store.commit('Chat/PushChatList', parseData.data[0]);
              }
              break;
            }
            case 'APISendAnnouncementMes': {
              const parseData = JSON.parse(data);
              const obj = { isAnnMessage: true, annMsg: parseData.data };
              this.$store.commit('Chat/PushChatList', obj);
              break;
            }
            case 'APILoginMB': {
              const parseData = JSON.parse(data);
              const { code } = parseData;
              if (code === 200) {
                // 200 = 成功
              } else if (code === -100) {
                // -100 = 失敗
              } else if (code === -105) {
                // -105 = 匿稱名未建立-提醒使用者需填路視窗訊息
              }
              this.$store.commit('Chat/SetLoginResultCode', code);
              break;
            }
            case 'APIsayHi':
              this.webSocketObj.send('@APIsayHi[]');
              break;
          }
        }
      },
      onOpen() {
        if (this.webSocketObj.readyState === 1) {
          this.reconnectTime = 0;
        }
      },
      onError() {
        this.ReconnectSocket();
      },
      onClose() {
        if (this.isReconnectLock) {
          return;
        }
        this.ReconnectSocket();
      },
      ReconnectSocket() {
        if (this.reconnectTime <= 5) {
          this.initWebsocket(this.initEvtID, true);
          this.reconnectTime++;
        }
      },
      APILoginMB() {
        const postData = {
          mbID: this.sessionAccount,
          token: this.sessionToken,
          EvtID: this.initEvtID,
        };
        this.webSocketObj.send(`@APILoginMB[${JSON.stringify(postData)}`);
      },
      changeRoom(initEvtID) {
        this.webSocketObj.send(`@APILoginMBEvtIDChangeRoom[${initEvtID}`);
      },
      SendMessage(message) {
        // console.log('this.webSocketObj:', this.webSocketObj, this.webSocketObj?.readyState);
        if (this.webSocketObj.readyState !== 1) {
          this.initWebsocket(this.initEvtID, true);
          setTimeout(this.initWebsocket, 1000);
        } else {
          this.webSocketObj.send(message);
        }
      },
      SendMessageWithTagName(name, msg) {
        this.webSocketObj.send(`@APIAtName[${JSON.stringify([name, msg])}`);
      },
      lockReconnect() {
        this.isReconnectLock = true;
        clearTimeout(this.timeOutEvent);
        this.timeOutEvent = setTimeout(() => {
          this.isReconnectLock = false;
        }, 2000);
      },
    },
  };
</script>
