import { render, staticRenderFns } from "./GamesNavMenu.vue?vue&type=template&id=2df01ad3"
import script from "./GamesNavMenu.vue?vue&type=script&lang=js"
export * from "./GamesNavMenu.vue?vue&type=script&lang=js"
import style0 from "./GamesNavMenu.vue?vue&type=style&index=0&id=2df01ad3&prod&lang=scss"
import style1 from "./GamesNavMenu.vue?vue&type=style&index=1&id=2df01ad3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.36_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_avk26xvnjlp5q4m4zyncj5yujy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports