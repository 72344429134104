import { render, staticRenderFns } from "./mWagerTypePopup.vue?vue&type=template&id=6e46a5ab&scoped=true"
import script from "./mWagerTypePopup.vue?vue&type=script&lang=js"
export * from "./mWagerTypePopup.vue?vue&type=script&lang=js"
import style0 from "./mWagerTypePopup.vue?vue&type=style&index=0&id=6e46a5ab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.36_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_avk26xvnjlp5q4m4zyncj5yujy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e46a5ab",
  null
  
)

export default component.exports